import { AxiosInstance } from 'axios'
import { ADMIN_API } from './constants'
import {
  ActiveQuestion,
  Agent,
  Certificate,
  Poll,
  QuestionsSection,
  ActivePollStatus,
  ProxyAgent,
  ZoomCredentials,
  RemoteMeeting,
  PendingEncryptedVotesValidation
} from './types'

type PollParams = {
  pollCode: string
}

export const getPoll = async (http: AxiosInstance, params: PollParams): Promise<Poll> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}`)
  return res.data
}

export const getQuestionsSections = async (http: AxiosInstance, params: PollParams): Promise<QuestionsSection[]> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/questions`)
  return res.data
}

// Gets the question in progress.
export const getActiveQuestion = async (
  http: AxiosInstance,
  params: PollParams
): Promise<{ status: ActivePollStatus; question: ActiveQuestion }> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/question`)
  const data = res.data

  // parses the status
  let status: ActivePollStatus = 'question-ready'
  if (res.status == 202 && data.code == 'NOT_STARTED') {
    status = 'poll-pending'
  } else if (res.status == 202 && data.code == 'STARTED') {
    status = 'poll-started'
  } else if (res.status == 202 && data.code == 'ANSWERED') {
    status = 'question-answered'
  } else if (res.status == 202 && data.code == 'WAITING') {
    status = 'question-pending'
  } else if (res.status == 202 && data.code == 'NO_MORE_QUESTIONS') {
    status = 'no-more-questions'
  } else if (res.status == 202 && data.code == 'WAITING_FOR_REPORTS') {
    status = 'report-being-processed'
  } else if (res.status == 200 && data.code == 'CLOSED_WITH_REPORTS') {
    status = 'closed-with-reports'
  } else if (res.status == 200 && data.code == 'CLOSED_NOT_ANSWERED') {
    status = 'closed-not-answered'
  }

  // parses the result
  const section = {
    id: data.id,
    title: data.title,
    description: data.description,
    visible: !data.hidden
  }

  const question = status == 'question-ready' ? { ...data.questions[0], section } : null

  return { status, question }
}

export const getAgent = async (http: AxiosInstance, params: PollParams): Promise<Agent> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/agent`)
  return res?.data
}

export const getProxyAgents = async (http: AxiosInstance, params: PollParams): Promise<ProxyAgent[]> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/proxy/agents`)
  return res.data
}

export const getCertificates = async (http: AxiosInstance, params: PollParams): Promise<Certificate[]> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/agent/certificates`)
  return res.data
}

export const getEarlyVoteReceipts = async (http: AxiosInstance, params: PollParams): Promise<Certificate[]> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/agent/receipts`)
  return res.data
}

export type RegisterProxyVoterParams = {
  pollCode: string
  body: {
    id: string
    legalPerson?: boolean
    legalName?: string
    legalDocumentId?: string
    name?: string
    surname?: string
    email?: string
    documentId: string
    prefixPhone?: string
    phone?: string
  }
}
export const registerProxyVoter = async (http: AxiosInstance, params: RegisterProxyVoterParams) => {
  const { pollCode, body } = params
  await http.post(`${ADMIN_API}/poll/${pollCode}/proxy/form`, body)
}

export const getPollFiles = async (http: AxiosInstance, params: PollParams): Promise<string[]> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/files`)
  return res?.data
}

export const getPollZoomCredentials = async (http: AxiosInstance, params: PollParams): Promise<ZoomCredentials> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/plugins/zoom/credentials`)
  return res.data
}

export const getRemoteMeetingLink = async (http: AxiosInstance, params: PollParams): Promise<RemoteMeeting> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/plugins/remote_meeting/link`)
  return res.data
}

export const getPendingEncryptedVotesValidation = async (
  http: AxiosInstance,
  params: PollParams
): Promise<PendingEncryptedVotesValidation> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/vote/validation/pending`)
  return res.data
}
